<template>
  <!-- 话单查询页面 -->
  <div class="bill">
    <!-- 筛选区域 -->
    <div class="screening">
      <el-dialog title="查询" v-model="theQueryDialog" :close-on-click-modal="false" :destroy-on-close="true"
        width="600px">
        <div class="">
          <el-form label-position="left" label-width="100px" size="small" :model="searchForm" ref="upform">
            <div class="form-out-box p-0-30">
              <el-form-item label="绑定ID：" prop="">
                <el-input v-model="searchForm.subId" size="mini" placeholder="请输入绑定ID"></el-input>
              </el-form-item>
              <el-form-item label="通话ID：" prop="">
                <el-input v-model="searchForm.callId" size="mini" placeholder="请输入通话ID"></el-input>
              </el-form-item>
              <el-form-item label="本地绑定ID：" prop="">
                <el-input size="mini" v-model="searchForm.bindId" placeholder="请输入本地绑定ID"></el-input>
              </el-form-item>
              <el-form-item label="主叫号码：" prop="">
                <el-input v-model="searchForm.telA" size="mini" placeholder="请输入主叫号码"></el-input>
              </el-form-item>
              <el-form-item label="企&ensp;业&ensp;号：" prop="">
                <el-input v-model="searchForm.telX" size="mini" placeholder="请输入企业号码"></el-input>
              </el-form-item>
              <el-form-item label="被叫号码：" prop="">
                <el-input v-model="searchForm.telB" size="mini" placeholder="请输入被叫号码"></el-input>
              </el-form-item>
              <el-form-item label="用户名：" prop="">
                <el-select v-model="searchForm.uid" placeholder="请选择用户名" style="width: 100%" clearable filterable>
                  <el-option v-for="(item, index) in user_list" :key="index" :label="`${item.nickname}(Id:${item.uid})`"
                    :value="item.uid">
                    <div class="flex-nowrap-space-between">
                      <div :class="item.status == 1?'no-red':''">
                        <span>{{ item.nickname }}</span>
                        <span class="ml-5">(Id:{{ item.uid }})</span>
                      </div>
                      <div v-if="item.status == 1" class="no-red">禁</div>
                    </div>
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="服务商：" prop="">
                <el-select v-model="searchForm.amountId" placeholder="请选择服务商" style="width: 100%" clearable filterable
                  @change="changeValues" size="mini">
                  <el-option v-for="(item, index) in amount_id" :key="index" :label="item.name" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="接口类型：" prop="">
                <el-select v-model="searchForm.interfaceId" placeholder="请选择接口类型" style="width: 100%" clearable
                  size="mini" filterable>
                  <el-option v-for="(item, index) in interface_id" :key="index" :label="item.name" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="通话状态：" prop="">
                <el-select v-model="searchForm.callStatus" placeholder="请选择通话状态" style="width: 100%" clearable
                  size="mini">
                  <el-option v-for="(item, index) in ['未接通','已接通']" :key="index" :label="item" :value="index">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="结束状态：" prop="">
                <el-select v-model="searchForm.finishStatus" placeholder="请选择结束状态" style="width: 100%" clearable
                  size="mini">
                  <el-option v-for="(item, index) in ['主叫挂断','被叫挂断','主叫放弃','被叫无应答','其他状态']" :key="index" :label="item"
                    :value="index">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="审核状态：" prop="">
                <el-select v-model="searchForm.auditStatus" placeholder="请选择审核状态" style="width: 100%" clearable
                  size="mini">
                  <el-option v-for="(item, index) in ['未审核','审核通过','审核不通过','审核中']" :key="index" :label="item"
                    :value="index">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="创建时间：">
                <el-date-picker class="input-searchFrom-width" v-model="valueTimeCreate" type="daterange"
                  start-placeholder="开始日期" end-placeholder="结束日期" value-format="YYYY-MM-DD" :default-time="defaultTime"
                  :clearable="false" size="mini">
                </el-date-picker>
              </el-form-item>
            </div>
            <el-form-item>
              <div class="flex-nowrap-flex-end">
                <el-button class="cancel" plain @click="searchTapReset" round>
                  重置
                </el-button>
                <el-button class="confirmAdd" @click="searchListFun" round>
                  查询
                </el-button>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </el-dialog>
    </div>
    <!-- 操作区域 -->
    <el-row>
      <el-col :span="12">
        <div class="title-name"></div>
      </el-col>
      <el-col :span="12">
        <div class="title-button">
          <div class="ml-10">
            <el-button icon="el-icon-search" class="button-el" size="medium" @click="theQueryDialog=true">查询</el-button>
          </div>
          <div class="ml-10">
            <el-button class="button-el" size="medium" @click="heavyPush=true">话单重推</el-button>
            
          </div>
          <div class="ml-10">
            <el-tooltip content="刷新列表" placement="top" effect="light">
              <div class="font-refresh-out flex-nowrap-center-center" @click="searchTapReset(1)">
                <icon-font v-if="loading == false" type="icon-shuaxin" class="font-refresh" />
                <i v-else class="el-icon-loading loding-color"></i>
              </div>
            </el-tooltip>
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- 数据展示 -->
    <el-row>
      <el-col>
        <div style="overflow-x: auto">
          <el-table v-loading="loading" ref="multipleTable" :data="tableData" size="mini" tooltip-effect="dark"
            style="width: 100%; overflow: auto" :row-style="{ height: '50px' }" :cell-style="{ padding: '0px' }"
            :header-cell-style="{ background: '#e0e5ff', color: '#24252F' }">
            <el-table-column fixed width="150" label="服务商/接口类型" :show-overflow-tooltip="true">
              <template #default="scope">
                <p>{{ amountText(scope.row.amountId) }}</p>
                <p>{{ interfaceText(scope.row.interfaceId) }}</p>
              </template>
            </el-table-column>
            <!--
            <el-table-column label="接口类型" fixed width="140px">
              <template #default="scope">
                <span>{{ interfaceText(scope.row.interfaceId) }}</span>
              </template>
            </el-table-column>
            -->
            <el-table-column label="用户" :show-overflow-tooltip="true" fixed width="120px">
              <template #default="scope">{{
                  scope.row.nickName == null ? "-" : scope.row.nickName
                }}</template>
            </el-table-column>
            <el-table-column label="绑定ID/通话ID/本地ID" :show-overflow-tooltip="true" width="300px">
              <template #default="scope">
                <p style="cursor:pointer" @click="copy(scope.row.subId)"><b>绑定ID:</b> {{ scope.row.subId == null ? "-" : scope.row.subId }}
                  <!-- <el-button icon="el-icon-document-copy" size="mini" @click="copy(scope.row.subId)" round></el-button> -->
                </p>
                <p style="cursor:pointer" @click="copy(scope.row.callId)"><b>通话ID:</b> {{ scope.row.callId == null ? "-" : scope.row.callId }}
                </p>
                <p style="cursor:pointer" @click="copy(scope.row.bindId)"><b>本地ID:</b> {{ scope.row.bindId == null ? "-" : scope.row.bindId }}
                </p>
              </template>
            </el-table-column>
            <!--
            <el-table-column label="通话ID" width="140px">
              <template #default="scope">{{
                scope.row.callId == null ? "-" : scope.row.callId
              }}</template>
            </el-table-column>
            <el-table-column label="本地绑定ID" width="160px">
              <template #default="scope">{{
                scope.row.bindId == null ? "-" : scope.row.bindId
              }}</template>
            </el-table-column>
            -->
            <el-table-column label="话费/成本" width="100px">
              <template #default="scope">
                {{ scope.row.price == null || scope.row.price == 0 ? "-" : scope.row.price }}
                /
                {{ scope.row.costPrice == null || scope.row.costPrice == 0 ? "-" : scope.row.costPrice }}
              </template>
            </el-table-column>
            <!--
            <el-table-column label="成本价格" width="110px">
              <template #default="scope">{{
                scope.row.costPrice == null || scope.row.costPrice == 0
                  ? "-"
                  : scope.row.costPrice
              }}</template>
            </el-table-column>
            -->
            <el-table-column label="主叫/小号/被叫" width="150px">
              <template #default="scope">
                <p><b>主叫:</b> {{ scope.row.telA == null ? "-" : scope.row.telA }}</p>
                <p><b>小号:</b> {{ scope.row.telX == null ? "-" : scope.row.telX }}</p>
                <p><b>被叫:</b> {{ scope.row.telB == null ? "-" : scope.row.telB }}</p>
              </template>
            </el-table-column>
            <el-table-column label="归属地" width="130px" :show-overflow-tooltip="true">
              <template #default="scope">
                <p>{{ scope.row.telAAttr == null ? "-" : scope.row.telAAttr }}</p>
                <p>-</p>
                <p>{{ scope.row.telBAttr == null ? "-" : scope.row.telBAttr }}</p>
              </template>
            </el-table-column>
            <!--            <el-table-column label="企业号" width="130px">
                          <template #default="scope">{{
                            scope.row.telX == null ? "-" : scope.row.telX
                          }}</template>
                        </el-table-column>
                        <el-table-column label="被叫号码" width="130px">
                          <template #default="scope">{{
                            scope.row.telB == null ? "-" : scope.row.telB
                          }}</template>
                        </el-table-column>-->
            <!-- <el-table-column label="回调地址" width="110px">
              <template #default="scope">{{
                scope.row.notifyUrl == null ? "-" : scope.row.notifyUrl
              }}</template>
            </el-table-column>
            <el-table-column label="回调参数" width="110px">
              <template #default="scope">{{
                scope.row.notifyData == null ? "-" : scope.row.notifyData
              }}</template>
            </el-table-column> -->
            <el-table-column label="通话时间" :show-overflow-tooltip="true" width="180px">
              <template #default="scope">
                <div class="grid">
                  <div>
                    <b>开始：</b>{{
                      scope.row.startTime == null ? "-" : scope.row.startTime
                    }}
                  </div>
                  <div>
                    <b>结束：</b>{{
                      scope.row.endTime == null ? "-" : scope.row.endTime
                    }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="通话/原始时长(秒)" width="125px">
              <template #default="scope">
                <p>
                  <span :class="scope.row.callStatus == 0 ? 'not-connected-color' : 'connected-color'">●</span>
                  {{ scope.row.duration == null || scope.row.duration == 0 ? "-" : scope.row.duration }}
                  /
                  {{ scope.row.originalDuration == null || scope.row.duration == 0 ? "-" : scope.row.originalDuration }}
                </p>
              </template>
            </el-table-column>
            <!--
            <el-table-column label="原始通话时长(秒)" width="140px">
              <template #default="scope">{{
                scope.row.originalDuration == null || scope.row.duration == 0
                  ? "-"
                  : scope.row.originalDuration
              }}</template>
            </el-table-column>
            -->

            <!--
            <el-table-column label="更新时间" width="200px">
              <template #default="scope">
                {{ scope.row.updateTime == null ? "-" : scope.row.updateTime }}
              </template>
            </el-table-column>
            -->
            <!--
            <el-table-column label="通话状态" width="110px">
              <template #default="scope">
                <span
                  :class="scope.row.callStatus == 0 ? 'no-red' : 'yes-green'"
                  >{{
                    scope.row.callStatus == null
                      ? "-"
                      : scope.row.callStatusText
                  }}</span
                >
              </template>
            </el-table-column>
            -->
            <el-table-column label="结束状态" width="70px">
              <template #default="scope">{{
                  scope.row.finishStatus == null || scope.row.finishStatus == 4
                      ? "-"
                      : scope.row.finishStatusText
                }}</template>
            </el-table-column>
            <!--
            <el-table-column label="审核状态" width="110px">
              <template #default="scope">
                <span
                  :class="scope.row.auditStatus == 0 ? 'no-red' : 'yes-green'"
                  >{{
                    scope.row.auditStatus == null
                      ? "-"
                      : scope.row.auditStatusText
                  }}</span
                >
              </template>
            </el-table-column>
            -->

            <el-table-column label="录音" fixed="right" width="120px">
              <template #default="scope">
                <span v-if="scope.row.recordUrl != null&&scope.row.recordUrlType == null&&scope.row.callStatus != 0"
                  @click="pullAway(scope.row)" type="success" class="button-font-color">
                  <icon-font type="icon-a-zu1512" />
                  获取录音
                </span>
                <span v-if="scope.row.recordUrlType == 1||scope.row.recordUrlType == 3" type="success"
                  class="button-font-color">
                  <i class="el-icon-loading loding-color-ly"></i>
                  正在获取...
                </span>

                <!-- <el-popover v-model:visible="scope.row.disabled" placement="top" :width="100" v-if="scope.row.recordUrlType == 1||scope.row.recordUrlType == 3">
                  <div>
                    <div>录音同步中，请耐心等待5分钟后再试~</div>
                    <div class="flex-nowrap-flex-end">
                      <span @click="scope.row.disabled = false" class="font-color-blue pointer">确认</span>
                    </div>
                  </div>
                  <template #reference>
                    <span type="success" class="button-font-color far-pos">
                      <i class="el-icon-loading loding-color-ly"></i>
                        正在获取...
                    </span>
                  </template>
                </el-popover> -->
                <audio v-if="scope.row.recordUrlType == 2" class="recordSon" :src="scope.row.recordUrl"
                  controls="controls" preload="load"></audio>
              </template>
            </el-table-column>
            <el-table-column fixed="right" label="详情">
              <template #default="scope">
                <el-tooltip content="详情" placement="top" effect="light">
                  <el-button class="editor" size="mini" icon="el-icon-s-order" @click="showRow(scope.row)" round>
                  </el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>
    <!-- 页码 -->
    <div class="pager">
      <div>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
          background :page-sizes="[10, 20, 30, 40]" :page-size="searchForm.pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>
    <div>
      <el-dialog title="列表详情" v-model="centerDialogVisible" width="50%" :destroy-on-close="true">
        <div class="center-body">
          <el-descriptions :column="1" border>
            <el-descriptions-item label="用户" width="60px" align="center">{{
                rowList.nickName == null ? "-" : rowList.nickName
              }}</el-descriptions-item>
            <el-descriptions-item label="回调地址" width="60px" align="center">{{
                rowList.notifyUrl == null ? "-" : rowList.notifyUrl
              }}</el-descriptions-item>
            <el-descriptions-item label="自定义参数" width="60px" align="center">{{
                rowList.notifyData == null ? "-" : rowList.notifyData
              }}</el-descriptions-item>
            <el-descriptions-item label="创建时间" width="60px" align="center">{{
                rowList.createTime == null ? "-" : rowList.createTime
              }}</el-descriptions-item>
          </el-descriptions>
        </div>
      </el-dialog>
      <el-dialog title="话单重推" v-model="heavyPush" width="600px" :destroy-on-close="true" :close-on-click-modal="false">
        <div class="center-body">
          <div class="form-out-box p-0-30">
            <el-form label-position="left" label-width="120px" :model="heavyPushform" ref="heavyPushform" size="mini"
              :rules="rules">
              <el-form-item label="用户名：" prop="">
                <el-select class="input-searchFrom-width" v-model="heavyPushform.uid" placeholder="请选择"
                  style="width: 100%" clearable filterable>
                  <el-option v-for="(item, index) in user_list" :key="index" :label="item.nickname" :value="item.uid">
                    <div class="flex-nowrap-space-between">
                      <div :class="item.status == 1?'no-red':''">
                        <span>{{ item.nickname }}</span>
                        <span class="ml-5">(Id:{{ item.uid }})</span>
                      </div>
                      <div v-if="item.status == 1" class="no-red">禁</div>
                    </div>
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="主叫号码：" prop="">
                <el-input v-model="heavyPushform.telA" class="input-searchFrom-width" size="mini" placeholder="请输入主叫号码">
                </el-input>
              </el-form-item>
              <el-form-item label="企&ensp;业&ensp;号：" prop="">
                <el-input v-model="heavyPushform.telX" class="input-searchFrom-width" size="mini" placeholder="请输入企业号码">
                </el-input>
              </el-form-item>
              <el-form-item label="被叫号码：" prop="">
                <el-input v-model="heavyPushform.telB" class="input-searchFrom-width" size="mini" placeholder="请输入被叫号码">
                </el-input>
              </el-form-item>
              <el-form-item label="服务商：" prop="">
                <el-select v-model="heavyPushform.amountId" class="input-searchFrom-width" placeholder="请选择服务商"
                  style="width: 100%" clearable filterable @change="changeValues" size="mini">
                  <el-option v-for="(item, index) in amount_id" :key="index" :label="item.name" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="接口类型：" prop="">
                <el-select v-model="heavyPushform.interfaceId" class="input-searchFrom-width" placeholder="请选择接口类型"
                  style="width: 100%" clearable size="mini">
                  <el-option v-for="(item, index) in interface_id" :key="index" :label="item.name" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="推送地址：" prop="">
                <el-input class="input-searchFrom-width" placeholder="请输入推送地址" v-model="heavyPushform.pushUrl"
                  size="mini"></el-input>
              </el-form-item>
              <el-form-item label="每秒推送并发量：" prop="qps">
                <el-input class="input-searchFrom-width" placeholder="请输入每秒推送并发量" type="number"
                  v-model="heavyPushform.qps" size="mini"></el-input>
              </el-form-item>
              <el-form-item label="是否推送：" prop="">
                <el-radio-group v-model="heavyPushform.type">
                  <el-radio :label="2">录音</el-radio>
                  <el-radio :label="1">话单</el-radio>
                  <el-radio :label="0">录音和话单</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="通话开始时间：" prop="heavyPushTime">
                <el-date-picker class="input-searchFrom-width" v-model="heavyPushform.heavyPushTime"
                  type="datetimerange" start-placeholder="开始日期" end-placeholder="结束日期"
                  value-format="YYYY-MM-DD HH:mm:ss" :default-time="defaultTime">
                </el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-button class="cancel" plain @click="cancelHeavyPush('heavyPushform')" round>取消</el-button>
                <el-button class="confirmAdd" @click="addHeavyPush('heavyPushform')" round>
                  开始重推</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import bill from '@/api/open/privacy/bill'
import interfacets from '@/api/open/privacy/interface'
import amount from '@/api/open/privacy/amount'
import { IconFont } from '@/utils/iconfont'
import userts from '@/api/web/user'
import { ElMessage } from 'element-plus'
import { getDate } from '@/utils/timeToChoose.js'
import { copy } from '@/utils/common.js'
export default {
  name: 'Bill',
  components: {
    IconFont,
  },
  data() {
    const validateheavyPushTime = (rule, value, callback) => {
      let time1 = value[0]
        .substring(0, value[0].indexOf(' '))
        .replace(/-/g, '/')
      let time2 = value[1]
        .substring(0, value[1].indexOf(' '))
        .replace(/-/g, '/')
      let timeNum1 = new Date(time1).getTime()
      let timeNum2 = new Date(time2).getTime()
      if (timeNum1 == timeNum2) {
        callback()
      } else {
        callback(
          new Error(
            '请选择一天内时间跨度(2021-01-01 00:00:00 至 2021-01-01 23:59:59)'
          )
        )
      }
      console.log(timeNum1, timeNum2)
    }
    const checkQps = (rule, value, callback) => {
      if (50 < value || value <= 0) {
        callback(new Error('50>=每秒推送并发量>0'))
      } else {
        callback()
      }
    }
    return {
      // visible:false,
      tableData: [], //当前页面数据
      activeNames: ['1'], //折叠面板
      loading: true, // loding 状态启用
      tableHeight: '', //表格高度
      // operationType: 0, //弹窗提交按钮文字状态
      currentPage: 1, //分页
      total: 0, //总数据条数
      interface_id: [], // 拿到的接口类型
      amount_id: [], // 拿到的通信账户类型
      valueTimeCreate: [], //选中时间段
      valueTimeAction: [], //选中时间段
      defaultTime: [
        new Date(2000, 1, 1, 0, 0, 0),
        new Date(2000, 2, 1, 23, 59, 59),
      ], //默认时间 '12:00:00', '08:00:00'
      searchForm: {
        // 搜索项
        page: 1, // 页数
        pageSize: 10, // 每一页数据量
        uid: '', // 用户ID
        callId: '', // 通话ID
        subId: '', // 上游绑定ID
        interfaceId: '', // 接口类型
        amountId: '', // 通信账户ID
        telA: '', // 主叫
        telX: '', // 企业号
        telB: '', // 被叫
        createTimeBegin: '', // 创建开始时间
        createTimeEnd: '', // 创建结束时间
        startTimeBegin: '', // 开始时间
        startTimeEnd: '', // 结束时间
        callStatus: '', // 通话状态（0:未接通 1:已接通）
        finishStatus: null, // 结束状态(0:主叫挂断 1:被叫挂断 2:主叫放弃 3:被叫无应答 4:其他状态)
        auditStatus: '', // 审核状态（0:未审核 1:审核通过 2:审核不通过）
      },
      rowList: {},
      centerDialogVisible: false,
      theQueryDialog: false,
      user_list: [],
      // 话单重推
      heavyPush: false,
      heavyPushform: {
        type: 2,
      },
      heavyPushTime: [],
      rules: {
        type: [
          {
            required: true,
            message: '请选择是否推送录音',
            trigger: 'change',
          },
        ],
        // heavyPushTime:[
        //   {
        //     type: 'array',
        //     required: true,
        //     message: '请选择通话开始时间',
        //      fields: {
        //       //tpye类型试情况而定,所以如果返回的是date就改成date
        //       0: { type: 'string', required: true, message: '请选择开始日期' },
        //       1: { type: 'string', required: true, message: '请选择结束日期' }
        //     }
        //   },
        // ],
        heavyPushTime: {
          required: true,
          trigger: 'change',
          validator: validateheavyPushTime,
        },
        qps: [{ validator: checkQps, trigger: 'blur' }],
      },
    }
  },
  mounted() {
    this.searchForm.createTimeBegin = getDate(0)
    this.searchForm.createTimeEnd = getDate(0)
    this.valueTimeCreate[0] = getDate(0)
    this.valueTimeCreate[1] = getDate(0)
    this.getDataList()
    this.getApiType()
    this.getUserList()
    this.getAmountType()
    this.tableHeight = this.$store.state.tableHeight
  },
  methods: {
    //复制
    copy(data) {
      copy(data)
      data = ''
    },
    showRow(row) {
      this.rowList = row
      this.centerDialogVisible = true
    },
    // 获取用户列表
    getUserList() {
      userts.queryList({}).then((res) => {
        this.user_list = res.data
      })
    },
    // 条件筛选
    searchListFun() {
      if (this.valueTimeCreate) {
        this.searchForm.createTimeBegin = this.valueTimeCreate[0]
        this.searchForm.createTimeEnd = this.valueTimeCreate[1]
      } else {
        this.searchForm.createTimeBegin = ''
        this.searchForm.createTimeEnd = ''
      }
      if (this.valueTimeAction) {
        this.searchForm.startTimeBegin = this.valueTimeAction[0]
        this.searchForm.startTimeEnd = this.valueTimeAction[1]
      } else {
        this.searchForm.startTimeBegin = ''
        this.searchForm.startTimeEnd = ''
      }
      if (this.searchForm.finishStatus === '') {
        this.searchForm.finishStatus = null
      }
      this.getDataList()
      this.theQueryDialog = false
    },
    // 条件重置
    searchTapReset(kind) {
      this.searchForm = {
        page: this.currentPage,
        pageSize: 10,
      }
      if (
        kind != 1 ||
        this.valueTimeCreate == null ||
        this.valueTimeCreate.length == 0
      ) {
        this.valueTimeCreate = [getDate(0), getDate(0)]
      }
      this.searchForm.createTimeBegin = this.valueTimeCreate[0]
      this.searchForm.createTimeEnd = this.valueTimeCreate[1]
      this.valueTimeAction = []
      this.searchForm.finishStatus = null
      if (kind == 1) {
        this.theQueryDialog = false
        this.getDataList()
      }
    },
    // 选中服务商
    changeValues(val) {
      this.searchForm.interfaceId = ''
      interfacets
        .getInterface({
          amountId: val,
        })
        .then((res) => {
          this.interface_id = res.data
        })
    },
    // 分页--条数
    handleSizeChange(val) {
      this.searchForm.pageSize = val
      this.getDataList()
    },
    // 分页--页码
    handleCurrentChange(val) {
      this.searchForm.page = val
      this.currentPage = val
      this.getDataList()
    },
    // 接口类型汉字
    interfaceText(id) {
      for (let i of this.interface_id) {
        if (i.id === id) {
          return i.name
        }
      }
    },
    // 通信账户汉字
    amountText(id) {
      if (id == 0) {
        return '主账户'
      } else {
        for (let i of this.amount_id) {
          if (i.id === id) {
            return i.name
          }
        }
      }
    },
    // 获取接口类型数组
    getApiType() {
      interfacets.seeApiList({}).then((res) => {
        this.interface_id = res.data
      })
    },
    // 获取通信账户类型
    getAmountType() {
      amount.getAmountList({}).then((res) => {
        this.amount_id = res.data
      })
    },
    // 获取当前页面数据
    getDataList() {
      this.loading = true
      // getBillList getEsBillList
      bill.getEsBillList({ ...this.searchForm }).then((res) => {
        this.tableData = res.data.records
        this.total = res.data.total
        if (res.code === 200) {
          this.loading = false
        }
      })
    },
    // 测试链接连通性, 主要检测404错误
    IsLoad(url) {
      return new Promise(function (resolve, reject) {
        var dom = document.createElement('link')
        dom.href = url
        dom.rel = 'stylesheet'
        document.head.appendChild(dom)
        dom.onload = function () {
          document.head.removeChild(dom)
          resolve()
        }
        dom.onerror = reject
      })
    },
    // 拉取
    pullAway(row) {
      row.recordUrlType = 1
      row.disabled = false
      this.IsLoad(row.recordUrl).then(
        function (data) {
          //处理resolve的代码
          row.recordUrlType = 2
          // console.log('1',data);
        },
        function (data) {
          //处理reject的代码
          row.recordUrlType = 3
          row.disabled = true
          ElMessage({
            message: '录音同步中，请耐心等待5分钟后再试~',
            type: 'warning',
          })
        }
      )
    },
    // 重推
    addHeavyPush(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // reEsPushBill rePushBill
          bill
            .esRePushBill({
              uid: this.heavyPushform.uid,
              pushUrl: this.heavyPushform.pushUrl,
              qps: this.heavyPushform.qps,
              type: this.heavyPushform.type,
              startTime: this.heavyPushform.heavyPushTime[0],
              endTime: this.heavyPushform.heavyPushTime[1],
              telA: this.heavyPushform.telA,
              telX: this.heavyPushform.telX,
              telB: this.heavyPushform.telB,
              amountId: this.heavyPushform.amountId,
              interfaceId: this.heavyPushform.interfaceId,
            })
            .then((res) => {
              if (res.code == 200) {
                ElMessage({
                  message: '话单正在重推中...',
                  type: 'success',
                })
                this.heavyPush = false
              }
            })
        } else {
          // console.log('error submit!!')
          return false
        }
      })
    },
    // 重推取消
    cancelHeavyPush(formName) {
      this.$refs[formName].resetFields()
      this.heavyPush = false
    },
  },
  created() {},
}
</script>
<style lang="scss" scoped>
.bill {
  margin-right: 15px;
  p {
    margin-top: 0;
    margin-bottom: 0;
  }
  /*.grid{
    display: gird;
    height: 60px;
    place-items:center;
  }*/
}
.title-buttom {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
}
.cancel {
  color: #637dff;
  border: #637dff solid 1px;
}
.confirmAdd {
  background: #637dff;
  color: #fff;
}
.recordSon {
  width: 110px;
  height: 32px;
}
.loding-color-ly {
  color: #637dff;
}
.far-pos {
  position: relative;
}
.son-pos {
  position: absolute;
  background: #000;
}
.form-out-box {
  height: 400px;
  margin-bottom: 20px;
  overflow-y: overlay;
}
.el-table .cell {
  line-height: 18px !important;
}
.connected-color {
  color: #21ce21;
}
.not-connected-color {
  color: red;
}
</style>